import { useContext, useEffect, useState } from 'react';
import { IHomePage, HomePageArticle, IGeneralData, GeneralData } from '../StrapiService'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import SanitizeHTML from '../components/SanitizeHTML'
import PrevNextButtons from '../components/PrevNextButtons'
import { AppContext } from '../app-context';
import { localeTo } from '../helpers';
import { Helmet } from 'react-helmet-async';

function HomePage() {
  const { t } = useTranslation();
  const appContext = useContext(AppContext)
  const [homePage, setHomePage] = useState<IHomePage | undefined>(undefined)
  const [generalData, setGeneralData] = useState<IGeneralData | undefined>(undefined)
  const [useCaseSelected, setUseCaseSelected] = useState(0)
  const [recommendationSelected, setRecommendationSelected] = useState(0)

  useEffect(() => {
    if (!homePage) {
      getData()
    }
  }, [appContext.locale])
  const getData = async () => {
    const hp = await HomePageArticle.getHomePageArticle(appContext.locale)
    const gd = await GeneralData.getGeneralData(appContext.locale)
    setHomePage(hp?.[0])
    setGeneralData(gd?.[0])
  }
  const mobileSlideImages = [
    'picts/jpgs/index-mobile-slide-1.jpg',
    'picts/jpgs/index-mobile-slide-2.jpg',
    'picts/jpgs/index-mobile-slide-3.jpg',
    'picts/jpgs/index-mobile-slide-4.jpg',
    'picts/jpgs/index-mobile-slide-5.jpg',
  ]
  const [mobileSlideImage, setMobileSlideImage] = useState(mobileSlideImages[0])

  const getFeatureBg = (bg: any) => {
    let intensity = '-lighten-1'
    if (bg === 'light') intensity = '-lighten-2' 
    if (bg === 'lightest') intensity = '-lighten-3' 
    return 'bg-info-block' + intensity
  }
  
  const getFeatures = () => homePage?.Features?.map((feature, index) => <div key={"feature-"+index} className={`feature ${getFeatureBg(feature.backgroundColor)} flex-grow mt-1`}>
    <h2>{feature.title}</h2>
    <p>{feature.subtitle}</p>
    <ul>
      {feature.feature1 &&
        <li key="feature.feature1">{feature.feature1}</li>
      }
      {feature.feature2 &&
        <li key="feature.feature2">{feature.feature2}</li>
      }
      {feature.feature3 &&
        <li key="feature.feature3">{feature.feature3}</li>
      }
    </ul>
    <div className="buttons mt-1">
      {feature.link1 &&
        <a href={feature.link1} className="btn btn-outline-grey small">{feature.link1Title}</a>
      }
      {feature.link2 &&
        <a href={feature.link2} className={`btn btn-outline-grey small${feature.link1 && ' ml-05'}`}>{feature.link2Title}</a>
      }
      {feature.backgroundImage?.url &&
      <img className="feature-image" src={process.env.REACT_APP_STRAPI_URL + feature.backgroundImage.url} />}
    </div>
  </div>)

  const getRecommendations = () => homePage?.Reviews?.map((review, index) => <div key={"recomedation-"+index} className={`${recommendationSelected === index ? '' : 'd-none ' }content`}>
    <div className="recomendation pt-sm-2">
      <div className="photo">
        {review.photo?.url &&
          <img className="feature-image" src={process.env.REACT_APP_STRAPI_URL + review.photo.url} alt={review.fullname} />
        }
      </div>
      {review.comment}
    </div>
    <div className="details mt-2">
      {review.fullname}<br />
      {review.organization}
    </div>
  </div>)

  return (
  <>
    <Helmet>
      <link rel="canonical" href="https://smartvissolution.com" />
    </Helmet>
    <div className="presentation-page flex column align-center">
      <div className="jumbotron flex column align-center">
        <div className="header flex column align-center">
          <h1 className="mt-3 mt-sm-4  px-sm-2 px-md-2">
            {homePage?.title}
          </h1>
          <h2 className="mt-1 px-sm-2 px-md-2">{homePage?.subtitle}</h2>
          <h4 className="mb-2 mt-1 px-sm-2 px-md-2">{homePage?.headertext}</h4>
          <div className="download mb-3 flex column align-center">
            {/* TODO: finish with download links */}
            <Link to={localeTo(appContext.locale,"/upload-photo")} className="btn btn-big btn-inline text-center btn-primary">
              {t("testnow")}
            </Link>
            <div className="platforms mt-05 text-small">
                {t("jumbotron.download.message")} <a href="//app.smartvissolution.com">
                {t("web")}
              </a>, {generalData?.appstorelink && <a href={generalData?.appstorelink}>iOS</a>}, {generalData?.googleplaylink && <a href={generalData?.googleplaylink}>Android</a>}
            </div>
          </div>
        </div>
        <div className="presentation">
          <img className="presentation-img" src="/picts/jpgs/admin-dark-lite.jpg" alt="Admin theme" />
        </div>
        <div className="bottom">
          <img src="/picts/svgs/index-jumbo-bottom.svg" alt="" />
        </div>
        <div className="dots-bg">
          <img src="/picts/jpgs/wall_XL.jpg" alt="Body" style={{ opacity: 0.6 }} />
        </div>
      </div>
      <div className="about max-content mt-3 px-sm-2 px-md-2">
        <div className="mobile-slider text-center">
          <div className="sticky-wrapper">
            <img src={mobileSlideImage} alt="Mobile slide 1" />
            <PrevNextButtons key="mobile-slider" positionsCount={mobileSlideImages.length} changePosition={(position: number) => {
              setMobileSlideImage(mobileSlideImages[position])
            }} />
          </div>
        </div>
        <h2>
          {t("about.title")}
          <span className="block">
          {t("about.subtitle")}
          </span>
        </h2>
        <div className="content flex row mt-3 align-start">
          <article className="flex-grow">
              <SanitizeHTML html={
                homePage?.about ?? ''
              } />
              {/* <p>
                An easy way to get skin diagnosed without entering a clinic. If you are a client just take a picture of your skind and the app will do the rest for you. With integrated AI and with the help of...
              </p>
              <p className="mb-4">
                Just any text here I am so happy, my dear friend, so absorbed in the exquisite sense of mere.
              </p> */}
              <div className="button-container mt-4">
                <Link to="/products" className="btn btn-outline">
                  {t("readmore")}
                </Link>
              </div>
              <div className="features mt-4 mb-3">
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-ai.svg" alt="" /><span className="ml-1">{t("about.icons.aidiagnoses")}</span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-lens.svg" alt="" /><span className="ml-1">{t("about.icons.lenssupport")}</span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-consultations.svg" alt="" /><span className="ml-1">{t("about.icons.econsultations")}</span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-moleautopos.svg" alt="" /><span className="ml-1">{t("about.icons.moleautopos")}</span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-dicom-hl7.svg" alt="" /><span className="ml-1">{t("about.icons.dicomhl")}</span>
                </div>
                <div className="feature flex align-center mb-1">
                  <img src="/picts/svgs/index-features-wave-modes.svg" alt="" /><span className="ml-1">{t("about.icons.multimode")}</span>
                </div>
              </div>
          </article>
          {/* <aside className="align-self-start"></aside> */}
        </div>
      </div>
      <div className="more-features flex flex-wrap row max-content px-sm-2 px-md-2">
        {getFeatures()}
      </div>
      <div className="use-cases-wrapper max-content px-sm-2 px-md-2">
        <div className="use-cases mt-1">
          <h2>{t("usecases")}</h2>
          <ul className="list-menu">
            {homePage?.UseCases?.map((uCase, index) => (
              <li
                key={"use-case-menu-" + uCase.id}
                onClick={() => setUseCaseSelected(index)}
                className={index === useCaseSelected ? "selected" : undefined}
              >
                {uCase.shortTitle}
              </li>
            ))}
          </ul>
          <div className="flex w-100">
            {homePage?.UseCases?.map((uCase, index) => (
              <article
                key={"use-case-article-" + uCase.id}
                className={index === useCaseSelected ? undefined : "d-none"}
              >
                <SanitizeHTML html={uCase.text ?? ""} />
                {uCase.link && (
                  <footer className="mt-2">
                    <a href={uCase.link} className="btn btn-primary">
                      {t("readmore")}
                    </a>
                  </footer>
                )}
              </article>
            ))}
          </div>
          {homePage?.UseCases?.map((uCase, index) => (
            uCase?.image?.url && (
              <img
                key={"use-case-img-" + uCase.id}
                src={process.env.REACT_APP_STRAPI_URL + uCase.image.url}
                className={index === useCaseSelected ? "case-image" : "d-none"}
              />
            )
          ))}
        </div>
      </div>
      <div className="technical-data max-content mt-3 mb-3 px-sm-2 px-md-2">
        <h2>
          {homePage?.footerarticletitle}
          <span>{homePage?.footerarticlesubtitle}</span>
        </h2>
        <article className="mt-3">
          <SanitizeHTML html={homePage?.footerarticle ?? ''} />
        </article>
        <div className="buttons text-right mt-3 flex justify-content-end">
          <a target="_blank" href="https://ifu.dermlite.com/files/pdf/FFH2-1701E.pdf"className="mr-2 download flex align-center justify-content-end">
            <img src="/picts/svgs/download-icon.svg" alt="download datasheet" className="mr-05" />
            {t("datasheet")}
          </a>
          <a target="_blank" href="https://dermlite.com/products/dermlite-handyscope" className="btn btn-outline">
            {t("readmore")}
          </a>
        </div>
      </div>
      <div className="recommendations flex column align-center w-100 text-center mt-sm-2">
        {getRecommendations()}
        <div className="recommendations-buttons max-content">
          <PrevNextButtons key="reviews-slider" positionsCount={homePage?.Reviews?.length ?? 0} changePosition={(position: number) => {
            setRecommendationSelected(position)
          }} />
        </div>
      </div>
    </div>
  </>
  )
}

export default HomePage
